<template>
  <div class="charg-detail-component">
    <div v-if="dataType == 'sendData'">
      <div class="detail-name">收信人</div>
      <div class="set-input">
        <div v-for="(item, index) in dataset" :key="index" class="set-list">
          <span> {{ item.buyerMobilePhone }}</span>
        </div>
      </div>
      <div class="detail-name list-margin">短信内容</div>
      <div class="message">
        <div>
          <div v-if="dataset.length > 1">
            【锦绣科学园】感谢您于{日期+时间}
            使用锦绣科学园小程序申请开具发票。很抱歉因系统临时
            维护未开票成功。目前开票功能已经恢复，您可再次打开小程序申请开票
            {URL Link}
          </div>
          <div v-else>
            <div v-for="(item, index) in dataset" :key="index">
              【锦绣科学园】感谢您于{{ item.invoiceDate }}
              使用锦绣科学园小程序申请开具发票。很抱歉因系统临时
              维护未开票成功。目前开票功能已经恢复，您可再次打开小程序申请开票
              {URL Link}
            </div>
          </div>
        </div>
      </div>
      <div class="detail-btn" align="right">
        <el-button @click="$emit('close', false)">取消</el-button>
        <el-button type="primary" @click="sendmMsg()">发送</el-button>
      </div>
    </div>
    <div v-if="dataType == 'keepData'">
      <div class="keep-list">
        <div class="keep-title">申请时间</div>
        <div class="keep-explan">
          {{ dataset[0].createTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}
        </div>
      </div>
      <div class="keep-list">
        <div class="keep-title">开票记录单号</div>
        <div class="keep-explan">
          {{ dataset[0].billNo }}
        </div>
      </div>
      <div class="keep-list">
        <div class="keep-title">开票金额</div>
        <div class="keep-explan">
          {{ dataset[0].totalAmount }}
        </div>
      </div>
      <div class="keep-list">
        <div class="keep-title">抬头类型</div>
        <div class="keep-explan">
          {{ dataset[0].openInvoiceType }}
        </div>
      </div>
      <div class="keep-list">
        <div class="keep-title">发票抬头</div>
        <div class="keep-explan">
          {{ dataset[0].buyerName }}
        </div>
      </div>
      <div class="keep-list">
        <div class="keep-title">手机号码</div>
        <div class="keep-explan">
          {{ dataset[0].buyerMobilePhone }}
        </div>
      </div>
      <div class="keep-list" v-if="dataset[0].sendMessage == '未发送短信提醒'">
        <div class="keep-title">提醒状态</div>
        <div class="keep-explan">
          {{ dataset[0].sendMessage }}
        </div>
      </div>
      <div class="keep-list" v-if="dataset[0].sendMessage == '已发送短信提醒'">
        <div class="keep-title">短信内容</div>
        <div class="keep-explan">
          【锦绣科学园】感谢您于
          {{ dataset[0].invoiceDate }}
          使用锦绣科学园小程序申请开具发票。很抱歉因系统
          临时维护未开票成功。目前开票功能已经恢复，您可再次打开小程序申请开票
          {URL Link}
        </div>
      </div>
      <div class="keep-list">
        <div class="keep-title">失败原因</div>
        <div class="keep-explan">
          {{ dataset[0].description }}
        </div>
      </div>
      <div class="detail-btn" align="right">
        <el-button @click="$emit('close', false)">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { sendingMessage } from "@/api/ruge/gsPark/customerService/invoicingFailure";
export default {
  name: "invoiceFailDetail",
  props: {
    dataset: {
      type: Array,
      default: [],
    },
    dataType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    sendmMsg() {
      let billNoList = [];
      this.dataset.forEach((item) => {
        billNoList.push(item.billNo);
      });
      billNoList = billNoList.join(",");
      sendingMessage({ billNoListStr: billNoList }).then((res) => {
        if (res.code === 200) {
          this.$message.success("发送短信成功");
          this.$emit("close", true);
        } else {
          this.$message.warning("发送短信失败！");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.charg-detail-component {
  .detail-name {
    height: 16px;
    font-size: 12px;
    font-family: Noto Sans SC-Bold, Noto Sans SC;
    font-weight: bold;
    color: #4e5969;
    line-height: 16px;
  }
  .list-margin {
    margin-top: 24px;
  }
  .set-input {
    margin-top: 4px;
    width: 304px;
    min-height: 40px;
    line-height: 40px;
    background: #f8f8f8;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dcdfe5;
    font-size: 14px;
    font-family: Noto Sans SC-Regular, Noto Sans SC;
    font-weight: 400;
    color: #606266;
    .set-list {
      display: inline-block;
      margin: 0 10px;
    }
  }
  .message {
    margin-top: 4px;
    // width: 652px;
    height: 54px;
    line-height: 20px;
    background: #f8f8f8;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dcdfe5;
    font-size: 14px;
    font-family: Noto Sans SC-Regular, Noto Sans SC;
    font-weight: 400;
    color: #303133;
    padding: 8px 4px 13px 8px;
  }
  .detail-btn {
    margin-top: 40px;
    margin-bottom: 22px;
  }
  .keep-list {
    margin-bottom: 30px;
    .keep-title {
      height: 18px;
      font-size: 12px;
      font-family: Noto Sans SC-Light, Noto Sans SC;
      font-weight: 300;
      color: #2f3941;
      line-height: 18px;
    }
    .keep-explan {
      margin-bottom: 4px;
      line-height: 20px;
      font-size: 14px;
      font-family: Noto Sans SC-Regular, Noto Sans SC;
      font-weight: 400;
      color: #303133;
      line-height: 20px;
    }
  }
}
</style>